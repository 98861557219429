import antdFA from "antd/lib/locale-provider/fa_IR";
import faMessages from "../locales/fa_IR.json";

const FaLang = {
  messages: {
    ...faMessages
  },
  antd: antdFA,
  locale: 'fa-IR',
};
export default FaLang;

export const notifications = [
  {
    id: 1,
    image: "https://via.placeholder.com/150",
    name: "میلاد لطفی",
    time: "6:19 بعرازظهر",
    message: "یک پیام تستی که از این کاربر ارسال شده دراینجا قرار گرفته ..",
    badge: 5
  },
  {
    id: 2,
    image: "https://via.placeholder.com/150",
    name: "آرمین بیات",
    time: "4:18 بعرازظهر",
    message: "یک پیام تستی که از این کاربر ارسال شده دراینجا قرار گرفته ..",
  },
  {
    id: 3,
    image:"https://via.placeholder.com/150",
    name: "آرمین بیات",
    time: "7:10 بعرازظهر",
    message: "یک پیام تستی که از این کاربر ارسال شده دراینجا قرار گرفته ..",
    badge: 8
  },
  {
    id: 4,
    image: "https://via.placeholder.com/150",
    name: "محمدجواد بنایی",
    time: "5:10 بعرازظهر",
    message: "یک پیام تستی که از این کاربر ارسال شده دراینجا قرار گرفته ..",
  },
  {
    id: 5,
    image: "https://via.placeholder.com/150",
    name: "حمید آذرلو",
    time: "7:35 بعرازظهر",
    message: "یک پیام تستی که از این کاربر ارسال شده دراینجا قرار گرفته ..",
    badge: 3
  }
];

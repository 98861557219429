const productData = [
  {
    thumb: "https://via.placeholder.com/600X400",
    name: 'ساعت هوشمند سامسونگ مدل Galaxy Watch4 Classic 46mm',
    variant: 'گلد',
    mrp: '990 تومان',
    price: '699 تومان',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'اسپیکر بلوتوثی قابل حمل پرووان مدل PSB4113',
    variant: 'سفید',
    mrp: '490 تومان',
    price: '399 تومان',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X338",
    name: 'هارد اکسترنال اپیسر مدل AC732 ظرفیت 5 ترابایت',
    variant: 'مشکی',
    mrp: '49 تومان',
    price: '39 تومان',
    offer: '30 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.3,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'ساعت هوشمند سامسونگ مدل Galaxy Watch Active2 44mm',
    variant: 'سفید',
    mrp: '29 تومان',
    price: '15 تومان',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.5,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'هدفون بی سیم انکر مدل SoundCore Liberty Air 2',
    variant: 'Black,500Htz',
    mrp: '400 تومان',
    price: '359 تومان',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4.2,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'دوربین مداربسته آنالوگ تی وی تی مدل TD-7520AS1L',
    variant: 'مشکی',
    mrp: '29 تومان',
    price: '15 تومان',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'هدفون بی سیم وان مور مدل iBFree Sport',
    variant: 'سفید',
    mrp: '10 تومان',
    price: '5 تومان',
    offer: '50 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'دوربین فیلم برداری ورزشی مدل A9',
    variant: 'گلد',
    mrp: '10 تومان',
    price: '20 تومان',
    offer: '50%',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'گوشی موبایل اپل مدل iPhone 13 Pro Max A2644 دو سیم‌ کارت ظرفیت 128 گیگابایت و رم 6 گیگابایت - اکتیو',
    variant: 'مشکی',
    mrp: '490 تومان',
    price: '399 تومان',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'لپ تاپ 15.6 اینچی دل مدل Inspiron 3501 - PD3',
    variant: 'مشکی',
    mrp: '49 تومان',
    price: '39 تومان',
    offer: '30 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.3,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'فلش مموری اپیسر مدل AH11D ظرفیت 64 گیگابایت',
    variant: 'White,full speed',
    mrp: '29 تومان ',
    price: '15 تومان ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.5,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'گوشی موبایل اپل مدل iPhone 12 Pro Max A2412 CH دو سیم‌ کارت ظرفیت 256 گیگابایت و رم 6 گیگابایت ',
    variant: 'Black,500Htz',
    mrp: '56 تومان ',
    price: '35 تومان ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4.2,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'کوله پشتی لپ تاپ رانیک پرایم مدل RP15 Stylish مناسب برای لپ تاپ 15 اینچی',
    variant: 'مشکی',
    mrp: '29 تومان ',
    price: '15 تومان ',
    offer: '49 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X450",
    name: 'گوشی موبایل سامسونگ مدل Galaxy A12 Nacho SM-A127F/DS دو سیم کارت ظرفیت 64 گیگابایت و رم 4 گیگابایت',
    variant: 'مشکی',
    mrp: '10 تومان ',
    price: '5 تومان ',
    offer: '50 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 3.1,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'محافظ کابل مدل PCM62',
    variant: 'گلد',
    mrp: '990 تومان ',
    price: '699 تومان ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 5,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  }, {
    thumb: "https://via.placeholder.com/600X400",
    name: 'اسپیکر بلوتوثی قابل حمل پرووان مدل PSB4113',
    variant: 'سفید',
    mrp: '490 تومان ',
    price: '399 تومان ',
    offer: '29 %',
    reviews: [
      {
        rating: 5,
        count: 3
      },
      {
        rating: 4,
        count: 5
      },
      {
        rating: 3,
        count: 5
      },
      {
        rating: 2,
        count: 0
      },
      {
        rating: 1,
        count: 3
      },
    ],
    rating: 4,
    description: 'توضیح خلاصه ای از این محصول رو اینجا میزاری تا توی نگاه اول مخاطب دربارش بخونه...',
  },
];

export default productData;

export const notifications = [

  {
    image: "https://via.placeholder.com/150",
    title: "مهسا شاهرودی اذ پروفایل شما بازدید کرد",
    time: "4:10 عصر",
    icon: "thumb-up gx-text-blue",
  }, {
    image: "https://via.placeholder.com/150",
    title: "یک پیام خصوصی جدید در میل باکس شما موجود است",
    time: "5:18 بعدازظهر",
    icon: "chat gx-text-grey",
  }, {
    image:"https://via.placeholder.com/150",
    title: "2 ماموریت انجام نشده در بخش پروفایل خود دارید",
    time: "5:36 بعدازظهر",
    icon: "birthday text-info",
  }, {
    image:"https://via.placeholder.com/150",
    title: "علی محمدی نیا شما را به رویداد  مدیران موفق دعوت نموده است",
    time: "5:54 قبل از ظهر",
    icon: "birthday gx-text-warning",
  }, {
    image: "https://via.placeholder.com/150",
    title: "حدیث باقرزاده پست شما را لایک کرد",
    time: "5:25 قبل از ظهر",
    icon: "profile gx-text-grey",
  }
];

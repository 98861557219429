import React from "react";
import {Button} from "antd";

import Widget from "components/Widget/index";


const NewPhotos = () => {
  return (
    <Widget styleName="gx-widget-bg">

      <span className="gx-widget-badge">20 ت /ماهانه</span>
      <i className="icon icon-camera gx-fs-xlxl"/>

      <h1 className="gx-fs-xxxl gx-font-weight-semi-bold gx-mb-3 gx-mb-sm-4">38,248 تصاویر</h1>
      <p>عکس های جدید در این هفته اضافه شد</p>
      <p>اکنون با طراحی بعدی خود شروع کنید. مشترک شوید و 20 تومان در ماه پس انداز کنید</p>
      <Button className="gx-mb-1 gx-btn-warning" htmlType="submit">
        مشترک شدن
      </Button>
    </Widget>
  );
};

export default NewPhotos;
